<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Reset Password</div>
      <a-form
        ref="registerForm"
        :model="forgotForm"
        :rules="rules"
        layout="vertical"
        class="mb-4"
        @finish="handleSubmit"
      >
        <a-form-item name="email">
          <a-input v-model:value="forgotForm.email" placeholder="Email Address" />
        </a-form-item>
        <a-button type="primary" class="text-center w-100" html-type="submit">
          <strong>Reset my password</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/login" class="vb__utils__link">
        <i class="fe fe-arrow-left mr-1 align-middle" />
        Go to Sign in
      </router-link>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue'
import apiClient, { baseURL } from '../../../../services/axios'
import { notification } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'VbForgotPassword',
  setup() {
    const rules = {
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
        },
        {
          type: 'email',
          message: 'Please enter a valid email address!',
          trigger: 'change',
        },
      ],
    }
    const forgotForm = reactive({
      email: '',
    })
    const router = useRouter()

    const handleSubmit = async () => {
      console.log('Email entered:', forgotForm.email)
      const { data } = await apiClient.post('/auth/reset-password', {
        email: forgotForm.email,
        role: 'patient',
      })

      notification.success({
        message: 'Success Reset Password',
        description: data.data,
      })
      router.push('/auth/login')
    }

    return {
      rules,
      forgotForm,
      handleSubmit,
    }
  },
}
</script>

<style lang="scss" module>
@import '@/@vb/components/Auth/style.module.scss';
</style>
